import React, { FunctionComponent, useEffect, useState } from "react";
import PublicConnection from "../../utils/connections/public";
import { useParams } from "react-router-dom";
import Spinner from "../../global/atoms/Spinner/Spinner";
import { IReviewPhotoSimple, IReviewVisit } from "../../utils/models";
import SeparatorLine from "../../global/atoms/separators/SeparatorLine";
import "lightbox2/dist/js/lightbox-plus-jquery.min";
import "lightbox2/dist/css/lightbox.min.css";
import Instances from "../../utils/connections/instances";
import { Col, IconButton } from "rsuite";
import { isEmpty } from "lodash";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import HeaderButtons from "../../global/atoms/headerButtons/HeaderButtons";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import toastNotification, { ToastTypes } from "../../global/ToastNotification";
import CheckIcon from "@rsuite/icons/Check";
import PropagateLoader from "react-spinners/PropagateLoader";
import ImportIcon from "@rsuite/icons/Import";

interface IVisitPhotos {}

interface IPhoto {
  photoSimple: Array<IReviewPhotoSimple>;
  visit: IReviewVisit;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VisitPhotos: FunctionComponent<IVisitPhotos> = () => {
  const { organizationId, projectId, visitId, questionId } = useParams<{
    organizationId: string;
    projectId: string;
    visitId: string;
    questionId: string;
  }>();

  const [photos, setPhotos] = useState<null | IPhoto>(null);
  const [loadingPhotos, setLoadingPhotos] = useState<any>(null);
  useEffect(() => {
    questionId
      ? PublicConnection.getQuestionPhotos(
          organizationId,
          projectId,
          visitId,
          questionId
        ).then((res) => {
          setPhotos(res.data.data[0]);
        })
      : PublicConnection.getVisitAllPhotos(
          organizationId,
          projectId,
          visitId
        ).then((res) => {
          setPhotos(res.data.data[0]);
        });
  }, []);

  if (photos === null) return <Spinner />;

  type TPhotosGrouped = Record<
    string,
    { activityName: string; questionName: string; photos: IReviewPhotoSimple[] }
  >;
  const photosGrouped: TPhotosGrouped = photos?.photoSimple.reduce(
    (acc: TPhotosGrouped, photo) => {
      const key = photo.questionId.toString();
      if (!acc[key]) {
        acc[key] = {
          activityName: photo.activityName,
          questionName: photo.questionName,
          photos: [],
        };
      }
      acc[key].photos.push(photo);
      return acc;
    },
    {}
  );

  const downloadAllPhotos = async () => {
    const zip = new JSZip();
    const folder = zip.folder("photos");

    if (!folder || isEmpty(photos.photoSimple)) {
      toastNotification(ToastTypes.warning, "Brak zdjęć do pobrania");
      return;
    }

    // Pobranie zdjęć z linków
    for (const photo of photos.photoSimple) {
      setLoadingPhotos((lf) => ({
        ...lf,
        [photo.value.refQuestionAnswerId]: "PROGRES",
      }));
      const photoUrl = Instances.getQuestionImageLink(
        photo.value.refQuestionAnswerId
      );
      try {
        const response = await fetch(photoUrl);
        if (response.ok) {
          const blob = await response.blob();
          const fileName = `${photo.taskName || "task"}_${
            photo.activityName || "activity"
          }_${photo.questionName || "photo"}_${
            photo.value.refQuestionAnswerId
          }_.jpg`;
          folder.file(fileName, blob);
          setLoadingPhotos((lf) => ({
            ...lf,
            [photo.value.refQuestionAnswerId]: "DONE",
          }));
        } else {
          setLoadingPhotos((lf) => ({
            ...lf,
            [photo.value.refQuestionAnswerId]: "FAULT",
          }));
          console.error(`Nie udało się pobrać zdjęcia: ${photoUrl}`);
          toastNotification(
            ToastTypes.warning,
            `Nie udało się pobrać zdjęcia: ${photoUrl}`
          );
        }
      } catch (error) {
        console.error(`Błąd pobierania zdjęcia: ${photoUrl}`, error);
      }
    }

    // Generowanie archiwum .zip
    zip.generateAsync({ type: "blob" }).then((zipContent) => {
      saveAs(
        zipContent,
        `${photos.visit.locationName} ${photos.visit.startDate}.zip`
      );
      setTimeout(() => {
        setLoadingPhotos(null);
      }, 2000);
    });
  };

  const optionOne = !location.host.includes("adecco");
  return (
    <>
      <HeaderButtons>
        <IconButton
          disabled={!!loadingPhotos}
          icon={<FileDownloadIcon />}
          appearance="ghost"
          onClick={downloadAllPhotos}>
          Pobierz .ZIP
        </IconButton>
      </HeaderButtons>
      <div>
        {photos?.visit && (
          <>
            Projekt: <strong>{photos.visit.projectName}</strong>
            <br />
            Lokalizacja: <strong>{photos.visit.locationName}</strong>
            {optionOne && (
              <>
                <br />
                Raportujący: <strong>{photos.visit.reportingUserName}</strong>
              </>
            )}
            <br />
            {questionId && (
              <>
                Zadanie: <strong>{photos.photoSimple[0].taskName}</strong>
                <br />
                Aktywność: <strong>{photos.photoSimple[0].activityName}</strong>
                <br />
                Pytanie: <strong>{photos.photoSimple[0].questionName}</strong>
                <br />
                Data: <strong>{photos.visit.startDate}</strong>
              </>
            )}
            <br />
          </>
        )}
      </div>

      <SeparatorLine size={2} />
      {isEmpty(photos) && <div style={{ textAlign: "center" }}>Brak zdjęć</div>}
      <Col
        xs={24}
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {photosGrouped &&
          Object.values(photosGrouped).map((photoItems) => {
            return (
              <>
                {!questionId && (
                  <>
                    <Col xs={24}>
                      Aktywność: <strong>{photoItems.activityName}</strong>
                      <br />
                      Pytanie: <strong>{photoItems.questionName}</strong>
                    </Col>
                  </>
                )}
                {photoItems.photos.map((p) => {
                  return (
                    <>
                      <Col
                        xs={12}
                        md={6}
                        lg={4}
                        xl={3}
                        style={{
                          overflow: "hidden",
                          border: "1px solid #E09616",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10px",
                          margin: "10px",
                          alignContent: "center",
                          borderRadius: "5px",
                          boxShadow: "0 0 5px 0 #E09616",
                        }}>
                        {loadingPhotos &&
                          loadingPhotos[p.value.refQuestionAnswerId] ==
                            "PROGRES" && (
                            <PropagateLoader
                              size={10}
                              color={"#E09616"}
                              style={{ position: "absolute" }}
                            />
                          )}

                        <div
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "50px",
                          }}>
                          {loadingPhotos &&
                            loadingPhotos[p.value.refQuestionAnswerId] ==
                              "DONE" && (
                              <CheckIcon style={{ color: "green" }} />
                            )}
                          {loadingPhotos &&
                            loadingPhotos[p.value.refQuestionAnswerId] ==
                              "FAULT" && (
                              <ImportIcon style={{ color: "red" }} />
                            )}
                        </div>
                        <a
                          style={{
                            display: "block",
                            width: "100%",
                          }}
                          href={Instances.getQuestionImageLink(
                            p.value.refQuestionAnswerId
                          )}
                          data-lightbox={`question-gallery`}
                          data-title={p.questionName}>
                          <img
                            src={p.photoContentThumbUrl ?? ""}
                            alt={""}
                            style={{ width: "100%" }}
                          />
                        </a>
                      </Col>
                    </>
                  );
                })}
              </>
            );
          })}
      </Col>
    </>
  );
};

export default VisitPhotos;
