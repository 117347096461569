import React, { FunctionComponent } from "react";
import ReactDOM from "react-dom";
import styles from "./styles.module.scss";
import { flatten } from "flat";
import {
  FILTER_INPUT_CHECKBOX,
  FILTER_INPUT_DATE,
  FILTER_MULTI_SELECT,
  FILTER_SELECT,
  IUniversalFilterElement,
} from "./UniversalFilters";
import { deepClone, parseDateForFrontend } from "../../utils/helpers";
import { GetString } from "./UniversalFilters";
import { Tag, Tooltip, Whisper } from "rsuite";
import _ from "lodash";

interface IFilterSelectedHeader {
  elements: Array<IUniversalFilterElement>;
  state: any;
  form: any;
  onRemoved: (elm: IUniversalFilterElement) => void;
}

const FilterSelectedHeader: FunctionComponent<IFilterSelectedHeader> = (
  props
) => {
  const FilterRowElm =
    document.getElementById("filter-row") ?? document.createElement("div");
  const parseSingleFilterValue = (
    stateKey: string,
    value: any,
    element: IUniversalFilterElement
  ) => {
    let val = deepClone(value);

    if (element.type === FILTER_INPUT_CHECKBOX) {
      val = element.label;
    } else if (element.type === FILTER_INPUT_DATE) {
      if (typeof value === "string") {
        if (value.split("-").length > 0) {
          // YYYY-mm-dd format
          val = parseDateForFrontend(
            parseInt((new Date(value).getTime() / 1000).toFixed(0))
          );
        } else {
          // string php timestamp
          val = parseDateForFrontend(parseInt(value));
        }
      } else if (typeof value === "number") {
        val = parseDateForFrontend(value); // php timestamp
      } else {
        console.error("parseSingleFilterValue unsupported value", typeof value);
      }
    } else if (
      [FILTER_SELECT, FILTER_MULTI_SELECT].indexOf(element.type) >= 0
    ) {
      const options = props.form[GetString(element.formKey)]?.options;
      let stateValue = props.state[GetString(element.stateKey)];
      if (typeof stateValue !== "object") stateValue = [stateValue];
      if (!_.isEmpty(stateValue)) {
        val = options
          ?.filter(
            (o) => stateValue.indexOf(o?.[element.valueKey ?? "id"]) >= 0
          )
          .map((o) => o?.[element.labelKey ?? "name"])
          //unique tag values only
          .filter((item, i, ar) => ar.indexOf(item) === i)
          .join(", ");
      }
    }

    return val;
  };

  return ReactDOM.createPortal(
    <div
      className={styles.activeFilterLabelWrapper}
      key={`filter_selected_container`}>
      {props.elements.map((elm) => {
        if (elm.headerLabelDisabled) return true;
        const flatStateKey = GetString(elm.stateKey);
        //@ts-ignore
        const value = flatten(props.state, { safe: true })[flatStateKey];
        if (!value || (typeof value === "object" && !value?.length))
          return true;

        return (
          <Whisper
            key={`whisper-${flatStateKey}`}
            placement="bottom"
            trigger="hover"
            speaker={
              <Tooltip>
                Filtr: <strong>{elm.label}</strong>
              </Tooltip>
            }>
            <Tag
              className={
                elm.required ? styles.requiredFilterTag : styles.filterTag
              }
              closable={!elm.required}
              onClose={() => props.onRemoved(elm)}>
              {parseSingleFilterValue(flatStateKey, value, elm)}
            </Tag>
          </Whisper>
        );
      })}
    </div>,
    FilterRowElm
  );
};

export default FilterSelectedHeader;
