import AxiosHelper from "../AxiosHelper";
import { AxiosPromise } from "axios";
import { getBaseApiUrl } from "../helpers";

const getBaseUrl = (organizationId: string, projectId: string): string => {
  return `${getBaseApiUrl()}/organizations/${organizationId}/projects/${projectId}`;
};

const PublicConnection = {
  getQuestionPhotos: (
    organizationId: string,
    projectId: string,
    visitId: string,
    questionId: string
  ): AxiosPromise<any> => {
    return AxiosHelper.get(
      getBaseUrl(organizationId, projectId) +
        `/visits/${visitId}/questions/${questionId}/photos`
    );
  },
  getVisitAllPhotos: (
    organizationId: string,
    projectId: string,
    visitId: string
  ): AxiosPromise<any> => {
    return AxiosHelper.get(
      getBaseUrl(organizationId, projectId) + `/visits/${visitId}/photos`
    );
  },
  getVisitData: (
    organizationId: string,
    projectId: string,
    visitId: string,
    projectReportId: string
  ): AxiosPromise<any> => {
    if (projectReportId == undefined) {
      return AxiosHelper.get(
        `${getBaseUrl(organizationId, projectId)}/visits/${visitId}/public`
      );
    } else {
      return AxiosHelper.get(
        `${getBaseUrl(
          organizationId,
          projectId
        )}/visits/${visitId}/public/${projectReportId}`
      );
    }
  },
  getNotificationVisitData: (
    notificationSourceId: string,
    visitId: string
  ): AxiosPromise<any> => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/public/notification-source/${notificationSourceId}/visits/${visitId}`
    );
  },
};

export default PublicConnection;
