import React, { FunctionComponent, useState } from "react";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import VisitsReportConnection from "../../../../utils/connections/visitsReport";
import { Button, Col, Message, Panel, PanelGroup, Row, Table } from "rsuite";
import BadgeStatus from "../../../../global/atoms/badge/BadgeStatus";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import { IFilterPaginate } from "../../../../utils/models";
import { defaultFilterPaginateState } from "../../../../utils/states";
import Pagination from "../../../../global/pagination/Pagination";
import {
  FormGroupDatePicker,
  FormGroupMultiSelect,
  FormGroupSelect,
  FormGroupText,
  FormRequired,
} from "utils/formHelper";
import { Helmet } from "react-helmet-async";
import { IVisitsReportForm } from "./VisitsReport";
import { getProjectIdFromUrl } from "utils/helpers";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import ActionsContainer from "global/atoms/ActionsContainer";

export interface IFilters {
  startDate: number;
  endDate: number;
  locationName: string;
  nip: string;
  street: string;
  city: string;
  community: string;
  district: string;
  voivodeshipId: string;
  voivodeshipIds: Array<string>;
  networkId: string;
  networkIds: Array<string>;
  groupIds: Array<string>;
  userRoleIds: Array<string>;
  projectId?: string;
  statusId: string;
  statusIds: Array<string>;
  locationId: string;
  locationIds: Array<string>;
  locationCustomerCode: string;
  locationCode: string;
  taskId: string;
  taskIds: Array<string>;
  locationIncrementId: string;
  dependGroupIds: Array<string>;
  userRoleDependIds: Array<string>;
}

interface ISelectVisitsList {
  form: IVisitsReportForm;
  chosenVisits: string[];
  setChosenVisits: any;
  visitsCount: number;
  setVisitsCount: any;
}

interface IVisit {
  id: string;
  locationName: string;
  date: string;
  status: string;
  reportingUser: string;
  statusColor: string;
}

// export const getDefaultFiltersState = (projectId: string): IFilters => {
//   return {
//     locationCode: "",
//     locationCustomerCode: "",
//     startDate: 0,
//     endDate: 0,
//     locationName: "",
//     nip: "",
//     street: "",
//     city: "",
//     community: "",
//     district: "",
//     voivodeshipId: "",
//     networkId: "",
//     groupId: "",
//     userRoleId: "",
//     projectId: projectId,
//     statusId: "",
//     locationId: "",
//     taskId: "",
//     locationIncrementId: "",
//   };
// };

export const MAX_VISITS_RESPONSE = 10000;

const SelectVisitsList: FunctionComponent<ISelectVisitsList> = ({
  form,
  chosenVisits,
  setChosenVisits,
  visitsCount,
  setVisitsCount,
}) => {
  const [visits, setVisits] = useState<Array<IVisit>>([]);
  const [paginate, setPaginate] = useState<IFilterPaginate>(
    defaultFilterPaginateState
  );
  const [loading, setLoading] = useState(false);

  enum FormFields {
    projectId = "projectId",
    locationId = "locationId",
    locationName = "locationName",
    locationIncrementId = "locationIncrementId",
    locationCode = "locationCode",
    locationCustomerCode = "locationCustomerCode",
    nip = "nip",
    // userRole[${data.roleId}] todo: ?
    networkId = "networkId",
    street = "street",
    community = "community",
    district = "district",
    voivodeshipId = "voivodeshipId",
    taskIds = "taskIds",
    statusIds = "statusIds",
    startDate = "startDate",
    endDate = "endDate",
    city = "city",
  }

  type TFormState = Partial<{
    [key in FormFields]: string | null | number | Date | Array<string>;
  }>;

  const defaultFormState = {
    startDate: dayjs().startOf("month").toDate(),
    endDate: dayjs().toDate(),
  };

  const [formState, setFormState] = useState(defaultFormState as TFormState);
  enum FilterPanel {
    location = "location",
    users = "users",
    visit = "visit",
    address = "address",
  }
  const [panelExpanded, setPanelExpanded] = useState<FilterPanel[]>([
    FilterPanel.visit,
  ]);

  const loadData = (selectAll = true) => {
    setLoading(true);
    const clonedFormState = cloneDeep(formState);
    clonedFormState.projectId = getProjectIdFromUrl();

    VisitsReportConnection.getVisits(clonedFormState)
      .then((response) => {
        setVisits(response?.data.data);
        setVisitsCount(response?.data.count);

        const isLimitedResponse = response?.data.count > MAX_VISITS_RESPONSE;

        if (isLimitedResponse) {
          setChosenVisits([]);
        } else if (selectAll) {
          setChosenVisits(response?.data.data.map((visit: IVisit) => visit.id));
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getDataSlicedByPage = () => {
    const startSliceFrom =
      (paginate.requestPaginate.page - 1) * paginate.requestPaginate.limit;
    return visits.slice(
      startSliceFrom,
      startSliceFrom + paginate.requestPaginate.limit
    );
  };

  const getUniqRolesFromUsers = () => {
    return form.users.options.reduce(
      (acc: Array<{ roleName: string; roleId: string }>, currentValue) => {
        const existingRole = acc.find(
          (role) => role.roleId === currentValue.roleId
        );
        if (!existingRole) {
          acc.push({
            roleName: currentValue.roleName,
            roleId: currentValue.roleId,
          });
        }
        return acc;
      },
      []
    );
  };

  const switchPanelExpanded = (panelName: FilterPanel) => {
    if (panelExpanded.includes(panelName)) {
      setPanelExpanded(panelExpanded.filter((item) => item !== panelName));
    } else {
      setPanelExpanded([...panelExpanded, panelName]);
    }
  };

  return (
    <div id="select-visits-list">
      <WhiteCard padding={true}>
        <FormRequired
          state={formState}
          requiredFields={{ date: ["startDate", "endDate"] }}
          onSubmit={(checkStatus: boolean) => {
            if (checkStatus) {
              loadData();
            }
          }}
          onChange={(state) => {
            setFormState(state);
          }}>
          <PanelGroup accordion bordered>
            <Panel
              bordered
              collapsible
              expanded={panelExpanded.includes(FilterPanel.location)}
              onSelect={() => switchPanelExpanded(FilterPanel.location)}
              header={"Lokalizacja"}>
              <Row>
                <Col lg={6} xs={12}>
                  <FormGroupText
                    fieldName={FormFields.locationId}
                    label={"Id lokalizacji"}
                    size={"xs"}
                  />
                </Col>

                <Col lg={6} xs={12}>
                  <FormGroupText
                    fieldName={FormFields.locationName}
                    label={"Nazwa lokalizacji"}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupText
                    fieldName={FormFields.locationIncrementId}
                    label={"Numer porządkowy lokalizacji"}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupSelect
                    fieldName={FormFields.locationCode}
                    label={"Kod zewnętrzny lokalizacji"}
                    options={form.locationCodes.options}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupText
                    fieldName={FormFields.locationCustomerCode}
                    label={"Kod klienta lokalizacji"}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupText
                    fieldName={FormFields.nip}
                    label={"NIP"}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupSelect
                    label={"Sieć"}
                    fieldName={FormFields.networkId}
                    options={form.networks.options}
                    size={"xs"}
                  />
                </Col>
              </Row>
            </Panel>

            <Panel
              expanded={panelExpanded.includes(FilterPanel.users)}
              onSelect={() => switchPanelExpanded(FilterPanel.users)}
              bordered
              collapsible
              header={"Użytkownicy"}>
              <Row>
                {getUniqRolesFromUsers().map((data, i) => (
                  <Col lg={6} xs={12} key={`user-${i}`}>
                    <FormGroupSelect
                      label={data.roleName}
                      fieldName={`userRoleIds[${data.roleId}]`}
                      labelKey={"username"}
                      valueKey={"userRoleId"}
                      options={form.users.options.filter(
                        (user: any) => user.roleId === data.roleId
                      )}
                      size={"xs"}
                    />
                  </Col>
                ))}
              </Row>
            </Panel>

            <Panel
              expanded={panelExpanded.includes(FilterPanel.address)}
              onSelect={() => switchPanelExpanded(FilterPanel.address)}
              bordered
              header={"Adres"}>
              <Row>
                <Col lg={6} xs={12}>
                  <FormGroupText
                    placeholder={""}
                    label={"Ulica"}
                    fieldName={FormFields.street}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupText
                    label={"Miasto"}
                    fieldName={FormFields.city}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupText
                    label={"Gmina"}
                    fieldName={FormFields.community}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupText
                    label={"Powiat"}
                    fieldName={FormFields.district}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupSelect
                    label={"Województwo"}
                    fieldName={FormFields.voivodeshipId}
                    options={form.voivodeships.options}
                    size={"xs"}
                  />
                </Col>
              </Row>
            </Panel>

            <Panel
              bordered
              collapsible
              expanded={panelExpanded.includes(FilterPanel.visit)}
              onSelect={() => switchPanelExpanded(FilterPanel.visit)}
              header={"Wizyta"}>
              <Row>
                <Col lg={6} xs={12}>
                  <FormGroupMultiSelect
                    label={"Zadania"}
                    fieldName={FormFields.taskIds}
                    options={form.tasks.options}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupMultiSelect
                    label={"Statusy"}
                    fieldName={FormFields.statusIds}
                    options={form.visitStatus.options}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupDatePicker
                    label={"Data od"}
                    cleanable={false}
                    fieldName={FormFields.startDate}
                    size={"xs"}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <FormGroupDatePicker
                    label={"Data do"}
                    cleanable={false}
                    fieldName={FormFields.endDate}
                    size={"xs"}
                  />
                </Col>
              </Row>
            </Panel>
          </PanelGroup>

          <SeparatorEmpty />

          <ActionsContainer>
            <Button
              type={"button"}
              appearance="default"
              onClick={() => {
                if (panelExpanded.length !== Object.keys(FilterPanel).length) {
                  setPanelExpanded([...Object.values(FilterPanel)]);
                } else setPanelExpanded([]);
              }}>
              Zwiń/Rozwiń wszystkie
            </Button>
            <Button
              type={"button"}
              appearance="ghost"
              onClick={() => {
                setFormState((s) => ({
                  ...Object.fromEntries(Object.keys(s).map((key) => [key, ""])),
                  ...defaultFormState,
                }));
              }}>
              Wyczyść
            </Button>
            <Button
              onClick={() => {
                loadData(false);
              }}
              type={"submit"}
              appearance={"primary"}
              disabled={loading}
              loading={loading}>
              Wyszukaj
            </Button>
          </ActionsContainer>
        </FormRequired>

        <SeparatorEmpty />
        {visitsCount > MAX_VISITS_RESPONSE && (
          <Message showIcon type={"info"}>
            Brak możliwości filtrowania znalezionych wizyt dla liczby większej
            od {MAX_VISITS_RESPONSE}.
          </Message>
        )}
        <Table autoHeight loading={loading} data={getDataSlicedByPage() ?? []}>
          <Table.Column width={40} align="left">
            <Table.HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox
                data={visits}
                state={chosenVisits}
                setState={setChosenVisits}
                keyIdName={"id"}
              />
            </Table.HeaderCell>
            <Table.Cell width={100} depth={0}>
              {(rowData) => (
                <ToggleSingleCheckbox
                  state={chosenVisits}
                  setState={setChosenVisits}
                  selectedId={rowData.id}
                />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1} width={130} align="left">
            <Table.HeaderCell depth={0} width={0}>
              Lokalizacja
            </Table.HeaderCell>
            <Table.Cell width={100} depth={0} dataKey={"locationName"} />
          </Table.Column>
          <Table.Column width={180} align="left">
            <Table.HeaderCell depth={0} width={0}>
              Raportujący
            </Table.HeaderCell>
            <Table.Cell width={100} depth={0} dataKey={"reportingUser"} />
          </Table.Column>
          <Table.Column width={180} align="left">
            <Table.HeaderCell depth={0} width={0}>
              Data
            </Table.HeaderCell>
            <Table.Cell width={100} depth={0} dataKey={"date"} />
          </Table.Column>
          <Table.Column width={180} align="right">
            <Table.HeaderCell depth={0} width={0}>
              Status
            </Table.HeaderCell>
            <Table.Cell width={100} depth={0}>
              {(rowData) => (
                <BadgeStatus color={rowData.statusColor}>
                  {rowData.status}
                </BadgeStatus>
              )}
            </Table.Cell>
          </Table.Column>
        </Table>

        <Pagination
          count={visits?.length ?? 0}
          style={{ marginTop: "10px" }}
          page={paginate.requestPaginate.page.toString()}
          limit={paginate.requestPaginate.limit.toString()}
          allowLimitChange={false}
          setState={setPaginate}
        />
      </WhiteCard>
      <Helmet>
        <style>{`
          #select-visits-list .rs-row .rs-col .rs-form-group {
            margin-bottom: 10px !important;
          }
          #select-visits-list .rs-form-control-label {
            font-size: 0.9em !important;
          }
        `}</style>
      </Helmet>
    </div>
  );
};

export default SelectVisitsList;
